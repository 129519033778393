import { FieldMapping, FieldMappingDirection } from '@integration-app/sdk'
import { uuid4 } from '@sentry/utils'
import { useState } from 'react'
import { GoToElementLink } from 'components/GoToElemenLink'
import { routeTo } from '../../routes-constants'
import { ValueType } from '@integration-app/ui/ComboBox/value-spec'
import { Cell, Row } from '@integration-app/ui/DataBuilderUI'
import ComboBox from '@integration-app/ui/ComboBox'
import {
  PopupCreateIntegrationSpecificFieldMapping,
  PopupCreateUniversalFieldMapping,
} from '../../Blueprints/FieldMappings/components/PopupCreateFieldMappingForm'
import { useFieldMappings } from '@integration-app/react'

const CREATE_OPTION_ID = uuid4()

export function FieldMappingSelect({
  direction,
  onChange,
  integrationId,
  fieldMappingKey,
  withCreateOption = true,
}: {
  integrationId?: string
  fieldMappingKey: string | undefined
  direction: FieldMappingDirection
  onChange: (value: string | undefined) => void
  withCreateOption?: boolean
}) {
  const [open, setOpen] = useState(false)

  const { fieldMappings } = useFieldMappings({
    integrationId: integrationId ?? 'null',
  })

  function handleChange(value: string | undefined) {
    if (value === CREATE_OPTION_ID) {
      setOpen(true)
      return
    }

    onChange(value)
  }

  const options = fieldMappings
    .filter((fieldMapping) => {
      const matchesDirection =
        direction === FieldMappingDirection.BOTH ||
        fieldMapping.direction === direction ||
        fieldMapping.direction === FieldMappingDirection.BOTH

      return matchesDirection
    })
    .map((fieldMapping) => ({
      label: fieldMapping.name,
      value: fieldMapping.key,
    }))

  if (withCreateOption) {
    options.push({
      label: '+ Create New Field Mapping',
      value: CREATE_OPTION_ID,
    })
  }

  const selected = fieldMappings.find(({ key }) => key === fieldMappingKey)

  return (
    <div className='flex items-center gap-4'>
      <Row>
        <Cell.Name shrink>Field Mapping</Cell.Name>
        <Cell.Combobox grow>
          <ComboBox
            placeholder='Select Field Mapping'
            value={fieldMappingKey}
            options={options}
            onChange={handleChange}
            valueSpec={{ type: ValueType.STRING, allowCustom: false }}
          />
        </Cell.Combobox>
      </Row>

      {selected && (
        <GoToElementLink to={routeTo.fieldMapping(selected)}>
          View/Edit Field Mapping
        </GoToElementLink>
      )}

      <FieldMappingCreatePopup
        open={open}
        onClose={() => setOpen(false)}
        onCreate={(fieldMapping) => handleChange(fieldMapping.key)}
        integrationId={integrationId}
      />
    </div>
  )
}

function FieldMappingCreatePopup({
  open,
  onClose,

  onCreate,

  dataSourceKey,
  integrationId,
}: {
  open: boolean
  onClose(): void

  onCreate(fieldMapping: FieldMapping): void

  dataSourceKey?: string
  integrationId?: string
}) {
  const isUniversal = !integrationId

  if (!open) return null

  const title = `Create Field Mapping`

  if (isUniversal) {
    return (
      <PopupCreateUniversalFieldMapping
        title={title}
        open={open}
        onClose={onClose}
        onCreate={onCreate}
        dataSourceKey={dataSourceKey}
        readOnlyDataSource
        direction={FieldMappingDirection.BOTH}
      />
    )
  }

  return (
    <PopupCreateIntegrationSpecificFieldMapping
      title={title}
      open={open}
      onClose={onClose}
      onCreate={onCreate}
      integrationId={integrationId}
      readOnlyIntegration
      dataSourceKey={dataSourceKey}
      readOnlyDataSource
      direction={FieldMappingDirection.BOTH}
    />
  )
}
