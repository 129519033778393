import {
  DataCollectionSelect,
  DataSourceConfigureEvents,
} from '@integration-app/ui'
import { ConfigCard } from 'components/Card/ConfigCard'
import UdmSelect from 'components/DataSources/UdmSelect'
import { RadioGroup } from 'components/RadioGroup'
import { VStack } from 'styled-system/jsx'
import { Text } from 'ui-kit/text'
import { useGenericConfig } from '../contexts/generic-config-context'
import { SharedDataSource } from './SharedDataSource'

enum DataSourceUnitConfigOption {
  Model = 'model',
  Shared = 'shared',
  Input = 'input',
}

export function UniversalDataSourceUnitConfig({
  configureEvents,
}: {
  configureEvents: boolean
}) {
  const {
    patchDataSourceConfig,
    dataSourceConfig,
    patchConfig,
    variablesSchema,
  } = useGenericConfig()

  async function handleSelectOption({
    value,
  }: {
    value: DataSourceUnitConfigOption
  }) {
    switch (value) {
      case DataSourceUnitConfigOption.Model:
        return patchConfig({
          dataSource: {
            udm: null,
          },
        })
      case DataSourceUnitConfigOption.Shared:
        return patchConfig({
          dataSource: {
            key: null,
          },
        })
      case DataSourceUnitConfigOption.Input:
        return patchConfig({
          dataSource: {
            collectionKey: null,
          },
        })
    }
  }

  let selectedOption = DataSourceUnitConfigOption.Model

  if (dataSourceConfig?.udm !== undefined) {
    selectedOption = DataSourceUnitConfigOption.Model
  }

  if (dataSourceConfig?.collectionKey !== undefined) {
    selectedOption = DataSourceUnitConfigOption.Input
  }

  if (dataSourceConfig?.key !== undefined) {
    selectedOption = DataSourceUnitConfigOption.Shared
  }

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Data Source</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={2}>
        <Text m={0}>
          Select which data in the external application you want to use in this
          integration.
        </Text>
        <RadioGroup.Root
          value={selectedOption}
          onValueChange={handleSelectOption}
          gap={1}
        >
          <RadioGroup.Item value={DataSourceUnitConfigOption.Model}>
            Pick best match for each application
          </RadioGroup.Item>
          <RadioGroup.Item value={DataSourceUnitConfigOption.Shared}>
            Use shared data source configuration
          </RadioGroup.Item>
          <RadioGroup.Item value={DataSourceUnitConfigOption.Input}>
            Use input variables
          </RadioGroup.Item>
        </RadioGroup.Root>

        <VStack gap={2} alignItems={'stretch'}>
          {selectedOption === DataSourceUnitConfigOption.Model && (
            <>
              <Text m={0}>
                Integration engine will automatically pick the best matching
                collection for each application based on the selected data
                model.
              </Text>
              <UdmSelect
                value={dataSourceConfig.udm}
                onChange={(udm) => patchDataSourceConfig({ udm })}
              />
            </>
          )}
          {selectedOption === DataSourceUnitConfigOption.Shared && (
            <>
              <Text m={0}>
                This data source configuration will be shared between every
                place that uses it.
              </Text>
              <SharedDataSource />
            </>
          )}
          {selectedOption === DataSourceUnitConfigOption.Input && (
            <>
              <Text m={0}>
                Select data collection dynamically using variables.
              </Text>
              <DataCollectionSelect
                value={dataSourceConfig}
                onChange={(dataSource) =>
                  patchConfig({
                    dataSource: {
                      ...(dataSource ?? {}),
                      collectionKey: dataSource?.collectionKey ?? null, // Always provide collectionKey to not reset selected option when un-selecting collection
                    },
                  })
                }
                variablesSchema={variablesSchema}
              />
              {configureEvents && (
                <DataSourceConfigureEvents
                  value={dataSourceConfig}
                  onChange={(dataSource) => patchConfig({ dataSource })}
                />
              )}
            </>
          )}
        </VStack>
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
