import { Integration } from '@integration-app/sdk'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { ConfigCard } from 'components/Card/ConfigCard'
import { GoToElementLink } from 'components/GoToElemenLink'
import { Icon } from 'ui-kit/icon'
import { routeTo } from '../../routes-constants'

export default function RelatedIntegrationSection({
  integration,
}: {
  integration?: Integration
}) {
  if (!integration) {
    return null
  }
  return (
    <ConfigCard.Root>
      <ConfigCard.Header
        leftSlot={
          <Icon>
            <SvgIcon type={SvgIconType.AppEntity} />
          </Icon>
        }
      >
        <ConfigCard.Title>
          Integration:
          <GoToElementLink to={routeTo.integration(integration)}>
            {integration.name}
          </GoToElementLink>
        </ConfigCard.Title>
      </ConfigCard.Header>
    </ConfigCard.Root>
  )
}
