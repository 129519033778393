import { Select, SelectItemType } from 'components/Select'
import { CodeExampleType } from '../../Docs/codeBlock-types'

export function CodeLanguageSelector({
  value,
  codeExamples,
  onChange,
}: {
  value?: CodeExampleType['language']
  codeExamples: CodeExampleType[]
  onChange: (value: CodeExampleType['language']) => void
}) {
  const items: Array<
    SelectItemType & {
      language: CodeExampleType['language']
    }
  > = codeExamples.map((codeExample) => ({
    language: codeExample.language,
    value: codeExample.language,
    label: codeExample.title,
  }))
  const selectedItem = items.find(({ language }) => language === value)

  function handleValueChange({
    language,
  }: SelectItemType & {
    language: CodeExampleType['language']
  }) {
    onChange(language)
  }

  return (
    <Select
      items={items}
      value={selectedItem}
      onValueChange={handleValueChange}
      width={'fit-content'}
      variant={'ghost'}
      size={'sm'}
      disabled={items.length === 1}
    />
  )
}
