import { DataSource, FieldMappingDirection } from '@integration-app/sdk'
import { StepCreateFieldMapping } from './StepCreateFieldMapping'

type PopupCreateFieldMappingProps = {
  title?: string
  open: boolean
  onClose: () => void
  onCreate: (item: DataSource) => void
  integrationId: string
  dataSourceKey?: string
  direction?: FieldMappingDirection
  readOnlyIntegration?: boolean
  readOnlyDataSource?: boolean
}

export function PopupCreateIntegrationSpecificFieldMapping({
  open,
  onCreate,
  onClose,
  ...props
}: PopupCreateFieldMappingProps) {
  function handleOnCreate(dataSource: DataSource) {
    onCreate(dataSource)
    onClose()
  }

  if (!open) return null

  return (
    <StepCreateFieldMapping
      onClose={onClose}
      onCreate={handleOnCreate}
      {...props}
    />
  )
}

export function PopupCreateUniversalFieldMapping({
  open,
  onClose,
  onCreate,
  ...props
}: Omit<
  PopupCreateFieldMappingProps,
  'integrationId' | 'readOnlyIntegration'
>) {
  function handleOnCreate(dataSource: DataSource) {
    onCreate(dataSource)
    onClose()
  }

  if (!open) return null

  return (
    <StepCreateFieldMapping
      onClose={onClose}
      onCreate={handleOnCreate}
      {...props}
    />
  )
}
