import { SchemaBuilder } from '@integration-app/ui'
import { Text } from 'ui-kit/text'
import { useGenericConfig } from './contexts/generic-config-context'
import { ConfigCard } from 'components/Card/ConfigCard'

export function HttpResponseConfig() {
  const { config, patchConfig } = useGenericConfig()

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Response Schema</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <Text m={0} marginBlockEnd={2}>
          What to expect from this request
        </Text>

        <SchemaBuilder
          schema={config.responseSchema}
          isObject={false}
          onChange={(responseSchema) => patchConfig({ responseSchema })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
