import { useAppDataSchema } from '@integration-app/react'
import { FieldMappingDirection } from '@integration-app/sdk'
import {
  FieldDescription,
  SchemaBuilder,
  SimpleInput,
} from '@integration-app/ui'
import { ButtonSvgIcon } from '@integration-app/ui/Button'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { Alert } from 'components/Alert'
import { IconButton } from 'components/Button/IconButton'
import { ConfigCard } from 'components/Card/ConfigCard'
import { ContentRows } from 'components/ContentRows'
import { RadioGroup } from 'components/RadioGroup'
import { PropsWithChildren, ReactNode, useState } from 'react'
import { TbInfoCircle, TbDotsVertical } from 'react-icons/tb'
import { NavLink, useNavigate } from 'react-router-dom'
import { routeTo } from 'routes/Workspaces/Workspace/routes-constants'
import useSWR from 'swr'
import { Icon } from 'ui-kit/icon'
import { Text } from 'ui-kit/text'
import AppDataSchemaSelect from '../../../../../../../components/AppDataSchemas/AppDataSchemaSelect'
import DataSourceSelect from '../../../../../../../components/DataSources/DataSourceSelect'
import SimpleSelect from '../../../../../../../components/SimpleSelect'
import useWorkspace from '../../../../../../../components/Workspaces/workspace-context'
import RelatedAppDataSchemaSection from '../../../../components/ConfigPaneSection/RelatedAppDataSchemaSection'
import RelatedDataSourceSection from '../../../../components/ConfigPaneSection/RelatedDataSourceSection'
import RelatedFieldMappingSection from '../../../../components/ConfigPaneSection/RelatedFieldMappingSection'
import RelatedIntegrationSection from '../../../../components/ConfigPaneSection/RelatedIntegrationSection'
import { WrapperWithCodeModeSwitch } from '../../../../components/ElementPageWrapperWithCodeMode'
import { useFieldMappingContext } from '../contexts/field-mapping-context'
import { ExportFieldMappingConfig } from './ExportFieldMappingConfig'
import {
  FrozenExportFieldsConfig,
  FrozenImportFieldsConfig,
} from './FrozenFieldsConfig'
import { ImportFieldMappingConfig } from './ImportFieldMappingConfig'
import { Menu } from 'components/Menu'

enum UniversalInternalSchemaOption {
  Auto = 'auto',
  Shared = 'shared',
  Custom = 'custom',
}

enum ChildInternalSchemaOption {
  Parent = 'parent',
  Custom = 'custom',
}

export const FIELD_MAPPING_DIRECTION_OPTIONS = [
  {
    value: FieldMappingDirection.IMPORT,
    label: 'Import (from external app to yours)',
  },
  {
    value: FieldMappingDirection.EXPORT,
    label: 'Export (from your app to external app)',
  },
  {
    value: FieldMappingDirection.BOTH,
    label: 'Bi-directional',
  },
]

export function FieldMappingConfiguration() {
  const { fieldMapping, onCodeChange, code } = useFieldMappingContext()

  return (
    <WrapperWithCodeModeSwitch
      key={fieldMapping.id} // to force rerender for code/ui switch
      code={code}
      onCodeChange={onCodeChange}
      height='full'
    >
      <ConfigCard.Wrapper px={7} pt={9} pb={16} minHeight={'full'}>
        <Config />
      </ConfigCard.Wrapper>
    </WrapperWithCodeModeSwitch>
  )
}

function Config() {
  const navigate = useNavigate()
  const { fieldMapping, onChange, archive, parent, dataSource } =
    useFieldMappingContext()

  // @ts-ignore
  const { appDataSchema } = useAppDataSchema({
    key: fieldMapping?.appSchema?.['$ref'],
  })

  const isChild = !!fieldMapping.universalFieldMappingId

  async function onArchive() {
    await archive()
    navigate(parent ? routeTo.fieldMapping(parent) : routeTo.fieldMappings())
  }

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header
          leftSlot={
            <Icon>
              <TbInfoCircle />
            </Icon>
          }
          menuSlot={
            <Menu.Root>
              <Menu.Trigger asChild>
                <span>
                  <IconButton
                    size={'dense'}
                    variant={'ghost'}
                    tooltip={'Actions'}
                  >
                    <TbDotsVertical />
                  </IconButton>
                </span>
              </Menu.Trigger>
              <Menu.Items
                items={[
                  {
                    value: 'archive',
                    label: 'Archive',
                    onClick: onArchive,
                  },
                ]}
              />
            </Menu.Root>
          }
        >
          <ConfigCard.Title>Basic Info</ConfigCard.Title>
        </ConfigCard.Header>

        <ConfigCard.Content>
          <ContentRows>
            <SimpleInput
              label='Name'
              value={fieldMapping.name}
              onChange={(name) => onChange({ name })}
            />

            <SimpleInput
              label='Key'
              value={fieldMapping.key}
              disabled={!!fieldMapping.universalFieldMappingId}
              onChange={(key) => onChange({ key })}
            />

            <SimpleSelect
              name='Direction'
              description={
                'Does it map fields from your app to external app or the other way around.'
              }
              required={true}
              disabled={!!fieldMapping.universalFieldMappingId}
              value={fieldMapping.direction}
              options={FIELD_MAPPING_DIRECTION_OPTIONS}
              onChange={(direction: FieldMappingDirection) =>
                onChange({ direction })
              }
            />
          </ContentRows>

          {isChild && (
            <Alert.Root marginBlockStart={3} icon={<TbInfoCircle />}>
              <Alert.Description>
                <Text m={0}>
                  This field mapping is based on a{' '}
                  <NavLink
                    className='link'
                    to={routeTo.fieldMapping(
                      fieldMapping.universalFieldMappingId!,
                    )}
                  >
                    universal field mapping
                  </NavLink>
                  .
                </Text>
                <Text m={0}>
                  You can edit direction, external, and internal fields schema
                  there.
                </Text>
              </Alert.Description>
            </Alert.Root>
          )}
        </ConfigCard.Content>
      </ConfigCard.Root>

      {fieldMapping.direction && (
        <>
          <ExternalFieldsConfig />
          {isChild ? (
            <ChildInternalFieldsConfig />
          ) : (
            <UniversalInternalFieldsConfig />
          )}
        </>
      )}

      <ConfigCard.Root>
        <ConfigCard.Header>
          <ConfigCard.Title>Related Elements</ConfigCard.Title>
        </ConfigCard.Header>
      </ConfigCard.Root>

      <ConfigCard.NestedWrapper>
        <RelatedFieldMappingSection fieldMapping={parent} />
        <RelatedIntegrationSection integration={fieldMapping.integration} />
        <RelatedDataSourceSection dataSource={dataSource} />
        <RelatedAppDataSchemaSection appDataSchema={appDataSchema} />
      </ConfigCard.NestedWrapper>
    </>
  )
}

function ExternalFieldsConfig() {
  const { fieldMapping, onChange, dataCollectionSpec } =
    useFieldMappingContext()

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header leftSlot={<SvgIcon type={SvgIconType.SyncRight} />}>
          <ConfigCard.Title>External Fields</ConfigCard.Title>

          <FieldDescription text={'Fields used in the external app'} />
        </ConfigCard.Header>
        <ConfigCard.Content>
          <DataSourceSelect
            integrationId={fieldMapping.integrationId}
            dataSourceKey={fieldMapping.dataSourceKey}
            disabled={!!fieldMapping.universalFieldMappingId}
            onChange={(dataSourceKey) => onChange({ dataSourceKey })}
          />
        </ConfigCard.Content>
      </ConfigCard.Root>

      <ConfigCard.NestedWrapper>
        {dataCollectionSpec && (
          <CollapsableConfigCard title='Schema' collapsed={true}>
            <SchemaBuilder readOnly schema={dataCollectionSpec?.fieldsSchema} />{' '}
          </CollapsableConfigCard>
        )}

        {(fieldMapping.direction === FieldMappingDirection.BOTH ||
          fieldMapping.direction === FieldMappingDirection.EXPORT) && (
          <ExportFieldMappingConfig />
        )}

        <FrozenExportFieldsConfig />
      </ConfigCard.NestedWrapper>
    </>
  )
}

function UniversalInternalFieldsConfig() {
  const { engineAdminFetcher } = useWorkspace()
  const { fieldMapping, onChange } = useFieldMappingContext()

  let selectedOption = UniversalInternalSchemaOption.Auto

  const { data: internalSchema, mutate } = useSWR(
    fieldMapping ? `field-mappings/${fieldMapping.id}/app-schema` : null,
    engineAdminFetcher,
  )

  async function onSchemaChange(newSchema) {
    await onChange({ appSchema: newSchema })
    await mutate(newSchema)
  }

  if (fieldMapping.appSchema) {
    selectedOption = UniversalInternalSchemaOption.Custom
  }

  if (fieldMapping.appSchema?.$ref !== undefined) {
    selectedOption = UniversalInternalSchemaOption.Shared
  }

  function handleSelectOption({
    value,
  }: {
    value: UniversalInternalSchemaOption
  }) {
    switch (value) {
      case UniversalInternalSchemaOption.Auto:
        return onSchemaChange(undefined)
      case UniversalInternalSchemaOption.Shared:
        return onSchemaChange({
          $ref: null,
        })
      case UniversalInternalSchemaOption.Custom:
        return onSchemaChange({
          type: 'object',
        })
    }
  }

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header leftSlot={<SvgIcon type={SvgIconType.SyncLeft} />}>
          <ConfigCard.Title>Internal Fields</ConfigCard.Title>
          <FieldDescription text={'Fields used in your app'} />
        </ConfigCard.Header>
        <ConfigCard.Content>
          <RadioGroup.Root
            mb={4}
            gap={1}
            value={selectedOption}
            onValueChange={handleSelectOption}
          >
            <RadioGroup.Item value={UniversalInternalSchemaOption.Auto}>
              Auto-detect from mapping
            </RadioGroup.Item>
            <RadioGroup.Item value={UniversalInternalSchemaOption.Custom}>
              Custom schema
            </RadioGroup.Item>
            <RadioGroup.Item value={UniversalInternalSchemaOption.Shared}>
              Shared App Data Schema
            </RadioGroup.Item>
          </RadioGroup.Root>

          {selectedOption === UniversalInternalSchemaOption.Shared && (
            <AppDataSchemaSelect
              value={fieldMapping.appSchema?.$ref}
              onChange={(key) =>
                onChange({
                  appSchema: {
                    $ref: key,
                  },
                })
              }
            />
          )}
        </ConfigCard.Content>
      </ConfigCard.Root>

      <ConfigCard.NestedWrapper>
        {selectedOption === UniversalInternalSchemaOption.Custom && (
          <ConfigCard.Root>
            <ConfigCard.Header>
              <ConfigCard.Title>Custom Schema</ConfigCard.Title>
            </ConfigCard.Header>
            <ConfigCard.Content>
              <SchemaBuilder
                schema={fieldMapping.appSchema}
                onChange={onSchemaChange}
              />
            </ConfigCard.Content>
          </ConfigCard.Root>
        )}

        {selectedOption !== UniversalInternalSchemaOption.Custom && (
          <ConfigCard.Root>
            <ConfigCard.Header>
              <ConfigCard.Title>Schema</ConfigCard.Title>
            </ConfigCard.Header>
            <ConfigCard.Content>
              <SchemaBuilder readOnly schema={internalSchema} />
            </ConfigCard.Content>
          </ConfigCard.Root>
        )}

        {(fieldMapping.direction === FieldMappingDirection.BOTH ||
          fieldMapping.direction === FieldMappingDirection.IMPORT) && (
          <ImportFieldMappingConfig />
        )}

        <FrozenImportFieldsConfig />
      </ConfigCard.NestedWrapper>
    </>
  )
}

function ChildInternalFieldsConfig() {
  const { engineAdminFetcher } = useWorkspace()
  const { fieldMapping, onChange } = useFieldMappingContext()

  const { data: internalSchema, mutate } = useSWR(
    fieldMapping ? `field-mappings/${fieldMapping.id}/app-schema` : null,
    engineAdminFetcher,
  )

  async function onSchemaChange(newSchema) {
    await onChange({ appSchema: newSchema })
    await mutate(newSchema)
  }

  let selectedOption = ChildInternalSchemaOption.Parent

  if (fieldMapping.appSchema) {
    selectedOption = ChildInternalSchemaOption.Custom
  }

  function handleSelectOption({ value }: { value: ChildInternalSchemaOption }) {
    switch (value) {
      case ChildInternalSchemaOption.Parent:
        return onSchemaChange(undefined)
      case ChildInternalSchemaOption.Custom:
        return onSchemaChange({ type: 'object' })
    }
  }

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header leftSlot={<SvgIcon type={SvgIconType.SyncLeft} />}>
          <ConfigCard.Title>Internal Fields</ConfigCard.Title>
          <FieldDescription
            text={'Data schema in your app to map fields to/from.'}
          />
        </ConfigCard.Header>
        <ConfigCard.Content>
          <RadioGroup.Root
            mb={4}
            gap={1}
            value={selectedOption}
            onValueChange={handleSelectOption}
          >
            <RadioGroup.Item value={ChildInternalSchemaOption.Parent}>
              Same as universal field mapping
            </RadioGroup.Item>
            <RadioGroup.Item value={ChildInternalSchemaOption.Custom}>
              Same as universal field mapping + custom fields
            </RadioGroup.Item>
          </RadioGroup.Root>
        </ConfigCard.Content>
      </ConfigCard.Root>

      <ConfigCard.NestedWrapper>
        <CollapsableConfigCard collapsed={true} title={'Schema'}>
          <SchemaBuilder readOnly schema={internalSchema} />
        </CollapsableConfigCard>

        {selectedOption === ChildInternalSchemaOption.Custom && (
          <ConfigCard.Root>
            <ConfigCard.Header>
              <ConfigCard.Title>Custom Schema</ConfigCard.Title>
            </ConfigCard.Header>
            <ConfigCard.Content>
              <SchemaBuilder
                schema={fieldMapping.appSchema}
                onChange={onSchemaChange}
              />
            </ConfigCard.Content>
          </ConfigCard.Root>
        )}

        {(fieldMapping.direction === FieldMappingDirection.BOTH ||
          fieldMapping.direction === FieldMappingDirection.IMPORT) && (
          <ImportFieldMappingConfig />
        )}
      </ConfigCard.NestedWrapper>
    </>
  )
}

export function CollapsableConfigCard({
  children,
  title,
  description,
  collapsed = false,
  leftSlot,
}: PropsWithChildren<{
  title: string
  description?: string
  collapsed?: boolean
  leftSlot?: ReactNode
}>) {
  const [isOpen, setIsOpen] = useState(!collapsed)

  return (
    <ConfigCard.Root>
      <ConfigCard.Header
        leftSlot={leftSlot}
        menuSlot={
          <IconButton
            variant={'ghost'}
            size={'sm'}
            tooltip={'Expand Output Schema'}
            onClick={() => setIsOpen(!isOpen)}
          >
            <ButtonSvgIcon
              type={isOpen ? SvgIconType.ChevronUp : SvgIconType.ChevronDown}
              className={'max-w-4 mr-0'}
            />
          </IconButton>
        }
      >
        <ConfigCard.Title>{title}</ConfigCard.Title>
        {description && <FieldDescription text={description} />}
      </ConfigCard.Header>

      {isOpen && <ConfigCard.Content>{children}</ConfigCard.Content>}
    </ConfigCard.Root>
  )
}
