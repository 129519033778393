import { ConfigCard } from 'components/Card/ConfigCard'
import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import { SchemaBuilder } from '@integration-app/ui'
import { Text } from 'ui-kit/text'

export function ApiTrigger() {
  const { config, patchConfig } = useGenericConfig()

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Input Parameters</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <Text m={0} marginBlockEnd={2}>
          Data you will be launching the flow with when using API
        </Text>

        <SchemaBuilder
          schema={config.inputSchema || { type: 'object' }}
          onChange={(inputSchema) => patchConfig({ inputSchema })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
