import { makeDataField } from '@integration-app/ui/DataBuilder'
import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import { ConfigCard } from 'components/Card/ConfigCard'
import { useGenericConfig } from './contexts/generic-config-context'

export function PaginationConfig() {
  const { variablesSchema, config, patchConfig } = useGenericConfig()

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Pagination</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <AdminDataBuilderForm
          onChange={(cursor) => patchConfig({ cursor })}
          field={makeDataField({
            value: config?.cursor,
            schema: {
              type: 'string',
              title: 'Cursor',
              description:
                'Cursor for getting the next page of results when iterating over pages.',
            },
            variablesSchema,
          })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
