import { OptionItem } from './components'
import { Combobox, SelectItemType, ComboboxProps } from 'components/Select'
import { useDebounceValue } from 'usehooks-ts'
import { FieldMapping } from '@integration-app/sdk'
import useIntegrationElement from '../hooks/useIntegrationElement'

export function FieldMappingComboboxSelect({
  value,
  onChange,
  name,
  ...props
}: Omit<ComboboxProps, 'items' | 'value' | 'onChange'> & {
  value?: string
  onChange: (value?: string) => void
  name?: string
}) {
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue(
    '',
    150,
  )
  const { items } = useIntegrationElement<FieldMapping>({
    route: 'field-mappings',
  })

  const options = items?.map((fieldMapping) => ({
    value: fieldMapping.key,
    label: fieldMapping.name,
  }))

  const filteredOptions = options?.filter((o) => {
    return [o.label, o.value]
      .map((x) => x.toLowerCase())
      .some((x) => x.includes(debouncedSearchValue.toLowerCase()))
  })
  const selectedOption = options?.find((o) => o.value === value)

  function handleChange(item: SelectItemType) {
    onChange(item?.value)
  }

  return (
    <Combobox
      items={filteredOptions}
      value={selectedOption}
      onValueChange={handleChange}
      placeholder={'Select a field mapping'}
      onSearchInputChange={setDebouncedSearchValue}
      TriggerTextComponent={(props) => (
        <OptionItem asTrigger name={name} {...props} />
      )}
      ItemTextComponent={(props) => <OptionItem {...props} />}
      {...props}
    />
  )
}
