import SimpleSelect, { SimpleSelectOptionType } from 'components/SimpleSelect'
import useWorkspace from 'components/Workspaces/workspace-context'
import useSWR from 'swr'
import { useGenericConfig } from '../common-configs/contexts/generic-config-context'

export default function ConnectorEventSelect({ value, onChange }) {
  const { engineAdminFetcher } = useWorkspace()
  const { integrationId } = useGenericConfig()

  const { data: connectorEvents } = useSWR(
    integrationId ? `/integrations/${integrationId}/events` : null,
    engineAdminFetcher,
  )

  const appEventOptions = connectorEvents?.map(
    ({ key, name }): SimpleSelectOptionType => ({
      value: key,
      label: name,
    }),
  )

  if (!appEventOptions) return null

  return (
    <div className={'mt-2'}>
      <SimpleSelect
        name='Connector Event'
        value={value}
        options={appEventOptions}
        onChange={onChange}
        disabled={!appEventOptions?.length}
      />
    </div>
  )
}
