import { useIntegration } from '@integration-app/react'
import { IconButton } from 'components/Button/IconButton'
import { CreateSmthPopup } from 'components/Popup/CreateSmthPopup'
import { TbChevronLeft } from 'react-icons/tb'
import { PropsWithChildren } from 'react'

export function CreateDataSourcePopupTitle({
  children,
  integrationId,
  onBack,
}: PropsWithChildren<{
  integrationId?: string
  onBack?(): void
}>) {
  const leftSlot = onBack ? (
    <IconButton
      tooltip={'Back'}
      colorPalette={'primary'}
      variant={'ghost'}
      onClick={onBack}
      size={'xs'}
      marginInlineStart={-3}
      marginBlock={-2}
    >
      <TbChevronLeft />
    </IconButton>
  ) : null

  return (
    <CreateSmthPopup.Title leftSlot={leftSlot}>
      {children || <CreateDataSourceTitle integrationId={integrationId} />}
    </CreateSmthPopup.Title>
  )
}

function CreateDataSourceTitle({ integrationId }: { integrationId?: string }) {
  const { integration } = useIntegration(integrationId)
  if (!integrationId) {
    return <>Create Universal Data Source</>
  }

  if (!integration?.name) {
    return <>Create Data Source</>
  }

  return <>Create Data Source for {integration?.name}</>
}
