import { ConfigCard } from 'components/Card/ConfigCard'
import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import { DataLinkTableAndDirectionConfig } from '../../../../../../../components/common-configs/DataLinkTableAndDirectionConfig'
import AdminDataBuilderForm from '../../../../../../../../../../components/AdminDataBuilderForm'
import { makeDataField } from '@integration-app/ui/DataBuilder'
import { NodeConfigurationErrorsGetter } from '../configuration-errors/types'
import { getBrokenVariablesConfigurationErrors } from '../configuration-errors/getBrokenVariablesConfigurationErrors'

export function FindDataLink() {
  const { config, patchConfig, variablesSchema } = useGenericConfig()

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Link Parameters</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <DataLinkTableAndDirectionConfig />

        <AdminDataBuilderForm
          onChange={(recordId) => patchConfig({ recordId })}
          field={makeDataField({
            schema: {
              type: 'string',
              title: 'Record ID',
            },
            value: config.recordId,
            variablesSchema,
          })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}

export const getFindDataLinkConfigErrors: NodeConfigurationErrorsGetter = ({
  runTimeVariablesSchema,
  config,
}) => {
  return getBrokenVariablesConfigurationErrors(config.recordId, [
    runTimeVariablesSchema,
  ])
}
