import {
  DataCollectionSelect,
  DataSourceConfigureEvents,
} from '@integration-app/ui'
import { ConfigCard } from 'components/Card/ConfigCard'
import { RadioGroup } from 'components/RadioGroup'
import { Text } from 'ui-kit/text'
import { useGenericConfig } from '../contexts/generic-config-context'
import { SharedDataSource } from './SharedDataSource'

enum DataSourceConfigOption {
  Shared = 'shared',
  Specific = 'specific',
}

export function IntegrationLevelDataSourceUnitConfig({
  configureEvents,
}: {
  configureEvents: boolean
}) {
  const { dataSourceConfig, patchConfig, variablesSchema } = useGenericConfig()

  async function handleSelectOption({
    value,
  }: {
    value: DataSourceConfigOption
  }) {
    switch (value) {
      case DataSourceConfigOption.Shared:
        return patchConfig({
          dataSource: {
            key: null,
          },
        })
      case DataSourceConfigOption.Specific:
        return patchConfig({
          dataSource: {
            collectionKey: null,
          },
        })
    }
  }

  let selectedOption = DataSourceConfigOption.Specific

  if (dataSourceConfig?.key !== undefined) {
    selectedOption = DataSourceConfigOption.Shared
  }

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Data Source</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={2}>
        <Text m={0}>
          Select which data in the external application you want to use in this
          integration.
        </Text>
        <RadioGroup.Root
          gap={1}
          value={selectedOption}
          onValueChange={handleSelectOption}
        >
          <RadioGroup.Item value={DataSourceConfigOption.Specific}>
            Select a data collection
          </RadioGroup.Item>
          <RadioGroup.Item value={DataSourceConfigOption.Shared}>
            Use shared data source configuration
          </RadioGroup.Item>
        </RadioGroup.Root>

        {selectedOption === DataSourceConfigOption.Shared && (
          <SharedDataSource />
        )}
        {selectedOption === DataSourceConfigOption.Specific && (
          <>
            <DataCollectionSelect
              value={dataSourceConfig}
              onChange={(dataSource) => patchConfig({ dataSource })}
              variablesSchema={variablesSchema}
            />
            {configureEvents && (
              <DataSourceConfigureEvents
                value={dataSourceConfig}
                onChange={(dataSource) => patchConfig({ dataSource })}
              />
            )}
          </>
        )}
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
