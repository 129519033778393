import { css } from 'styled-system/css'
import * as StyledSwitch from 'ui-kit/styled/switch'
import { SwitchProps } from 'ui-kit/switch'
import { ChildrenOrTooltipProps, SwitchControl } from './index'

const onOffClasses = css({
  position: 'relative',
  _before: {
    position: 'absolute',
    zIndex: 'base',
    inset: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    content: '"off"',
    fontSize: '2xs',
    lineHeight: 'none',
    color: 'fg.default',
    transitionDuration: 'normal',
    transitionProperty: 'color',
    transitionTimingFunction: 'default',
  },
  _checked: {
    _before: {
      content: '"on"',
      justifyContent: 'start',
      color: 'accent.fg',
    },
  },
})

export type OnOffSwitchProps = Omit<SwitchProps, 'children'> &
  ChildrenOrTooltipProps

export function OnOffSwitch({
  children,
  tooltip,
  ...rootProps
}: OnOffSwitchProps) {
  return (
    <StyledSwitch.Root {...rootProps}>
      <SwitchControl tooltip={tooltip} className={onOffClasses}>
        <StyledSwitch.Thumb />
      </SwitchControl>
      {children && <StyledSwitch.Label>{children}</StyledSwitch.Label>}
      <StyledSwitch.HiddenInput />
    </StyledSwitch.Root>
  )
}
