import { Portal } from '@ark-ui/react'
import { Box, BoxProps } from 'styled-system/jsx'
import { Heading, HeadingProps } from 'ui-kit/heading'
import { Text, TextProps } from 'ui-kit/text'
import { Popover as UiKitPopover } from 'ui-kit/popover'
import type { ComponentProps } from 'styled-system/types'

function Root({
  children,
  ...props
}: ComponentProps<typeof UiKitPopover.Root>) {
  return (
    <UiKitPopover.Root lazyMount unmountOnExit {...props}>
      {children}
    </UiKitPopover.Root>
  )
}

function Window({
  children,
  usePortal,
  ...props
}: ComponentProps<typeof UiKitPopover.Content> & { usePortal?: boolean }) {
  return (
    <Portal disabled={!usePortal}>
      <UiKitPopover.Positioner>
        <UiKitPopover.Content
          padding={0}
          display='grid'
          gridTemplateAreas='"header" "content" "footer"'
          gridTemplateRows='min-content 1fr min-content'
          gap={0}
          {...props}
        >
          <UiKitPopover.Arrow>
            <UiKitPopover.ArrowTip />
          </UiKitPopover.Arrow>
          {children}
        </UiKitPopover.Content>
      </UiKitPopover.Positioner>
    </Portal>
  )
}

function Header({ children, ...props }: BoxProps) {
  return (
    <Box
      gridArea='header'
      paddingInline='5'
      paddingBlockStart='4'
      paddingBlockEnd='3'
      {...props}
    >
      {children}
    </Box>
  )
}

function Content({ children, ...props }: BoxProps) {
  return (
    <Box
      gridArea='content'
      overflowY='auto'
      paddingInline='4'
      paddingBlock='4'
      {...props}
    >
      {children}
    </Box>
  )
}

function Footer({ children, ...props }: BoxProps) {
  return (
    <Box
      gridArea='footer'
      paddingInline='5'
      paddingBlockStart='3'
      paddingBlock='4'
      {...props}
    >
      {children}
    </Box>
  )
}

function Title({ children, ...props }: HeadingProps) {
  return (
    <UiKitPopover.Title {...props} asChild>
      <Heading as={'h2'}>{children}</Heading>
    </UiKitPopover.Title>
  )
}

function Description({ children, ...props }: TextProps) {
  return (
    <UiKitPopover.Description {...props} asChild>
      <Text>{children}</Text>
    </UiKitPopover.Description>
  )
}

const Popover = {
  Root,
  Window,
  Header,
  Content,
  Footer,
  Title,
  Description,
  Trigger: UiKitPopover.Trigger,
  CloseTrigger: UiKitPopover.CloseTrigger,
}
export { Popover }
