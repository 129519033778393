import classes from 'routes/Docs/components/DocsSearch/AlgoliaSearch.module.css'
import { SearchInput } from '@integration-app/ui/Input'
import { Hit } from 'instantsearch.js'
import { removePrefixNumberFromPath } from 'routes/Docs/helpers/helpers'
import DocsBreadcrumbs from 'routes/Docs/components/DocBreadcrumbsFromPath'
import DocLink from 'components/Docs/DocLink'
import { Snippet } from 'react-instantsearch'

export function AlgoliaSidebarSearchPlaceholder({ onClick }) {
  return (
    <div
      className={classes.sidebarSearch}
      onMouseDown={(event) => {
        event.preventDefault()
        onClick()
      }}
    >
      <SearchInput
        className={classes.sidebarSearch_input}
        onChange={() => {}}
        placeholder='Type to search'
      />
      <span className={classes.sidebarSearch_shortcuts}>
        <kbd className={'visible-on-mac'}>
          <MacIcon />
        </kbd>
        <kbd className={'visible-not-on-mac'}>Ctrl</kbd>+<kbd>K</kbd>
      </span>
    </div>
  )
}

export function AlgoliaSearchResult({ hit }: { hit: Hit }) {
  const { objectID, title } = hit

  const path = removePrefixNumberFromPath(objectID)

  return (
    <div className={classes.searchResultItem} key={objectID}>
      <h3 className={classes.searchResultItem_header}>
        <DocLink path={path}>{title}</DocLink>
      </h3>

      <div className={classes.searchResultItem_description}>
        {/* NOTE: Snippet length is configured through `attributesToSnippet` setting here https://www.algolia.com/apps/Q9GPIDDV0Q/explorer/configuration/docs/snippeting. */}
        {/* See more info in algolia docs https://www.algolia.com/doc/guides/building-search-ui/ui-and-ux-patterns/highlighting-snippeting/react/#snippeting */}
        <Snippet hit={hit} attribute='text' highlightedTagName='b' />
      </div>

      <div className={classes.searchResultItem_breadcrumbs}>
        <DocsBreadcrumbs path={path} />
      </div>
    </div>
  )
}

function MacIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox={'0 0 24 24'}
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M7 9a2 2 0 1 1 2-2v10a2 2 0 1 1-2-2h10a2 2 0 1 1-2 2V7a2 2 0 1 1 2 2H7' />
    </svg>
  )
}
