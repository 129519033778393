import { makeDataField } from '@integration-app/ui/DataBuilder'
import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import { ConfigCard } from 'components/Card/ConfigCard'
import { useNodeConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors'
import { getBrokenVariablesConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/getBrokenVariablesConfigurationErrors'
import {
  ConfigurationErrorType,
  NodeConfigurationErrorData,
  NodeConfigurationErrorsGetterArgs,
} from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'
import { useGenericConfig } from './contexts/generic-config-context'

const SCHEMA = {
  type: 'string',
  title: 'Record ID',
}

export function RecordIdFieldConfig() {
  const { config, patchConfig, variablesSchema } = useGenericConfig()

  const idField = makeDataField({
    schema: SCHEMA,
    variablesSchema,
    value: config.id,
  })

  const { errorFieldsLocators } = useNodeConfigurationErrors()

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Record Id</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <AdminDataBuilderForm
          field={idField}
          onChange={(id) => patchConfig({ id })}
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2322): Type '(string | undefined)[]' is not assignable to... Remove this comment to see the full error message
          errorFieldsLocators={errorFieldsLocators} // TODO
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}

export function getRecordIdConfigErrors({
  runTimeVariablesSchema,
  config,
  udm,
}: NodeConfigurationErrorsGetterArgs): NodeConfigurationErrorData[] {
  const errors: NodeConfigurationErrorData[] = []

  const recordId = config.id

  if (!recordId && udm) {
    errors.push({
      type: ConfigurationErrorType.MissingFieldValue,
      message: 'Record ID is required.',
      valueLocator: '$',
    })
  }

  const brokenVariablesErrors = getBrokenVariablesConfigurationErrors(
    recordId,
    [runTimeVariablesSchema],
  )

  errors.push(...brokenVariablesErrors)

  return errors
}
