import { useConnections } from '@integration-app/react'
import { IntegrationIcon } from '@integration-app/ui'
import {
  ItemTextComponentType,
  SelectItemType,
  Combobox,
  ComboboxProps,
} from 'components/Select'
import React, { ComponentProps } from 'react'
import { Flex } from 'styled-system/jsx'
import { useDebounceValue } from 'usehooks-ts'

export function ConnectionComboboxSelect({
  userId = undefined,
  value,
  onChange,
  name,
  ...props
}: Omit<ComboboxProps, 'items' | 'value' | 'onChange'> & {
  value?: string
  onChange: (value?: string) => void
  userId?: string
  name?: string
}) {
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue(
    '',
    150,
  )
  const { items } = useConnections({ userId })

  const options = items?.map((conn) => ({
    value: conn.id,
    label: conn.name,
    integration: conn.integration,
  }))

  const filteredOptions = options?.filter((o) => {
    return [o.label, o.value]
      .map((x) => x.toLowerCase())
      .some((x) => x.includes(debouncedSearchValue.toLowerCase()))
  })
  const selectedOption = options?.find((o) => o.value === value)

  function handleChange(item: SelectItemType) {
    onChange(item?.value)
  }

  return (
    <Combobox
      items={filteredOptions}
      value={selectedOption}
      onValueChange={handleChange}
      placeholder={'Select a connection'}
      onSearchInputChange={setDebouncedSearchValue}
      TriggerTextComponent={(props) => (
        <OptionItem asTrigger name={name} {...props} />
      )}
      ItemTextComponent={(props) => <OptionItem {...props} />}
      {...props}
    />
  )
}

function OptionItem({
  item,
  asTrigger,
  name,
}: ComponentProps<ItemTextComponentType> & {
  asTrigger?: boolean
  name?: string
}) {
  if (asTrigger && name) {
    return (
      <Flex justifyItems={'start'} alignItems={'center'} gap={2} width={'full'}>
        {name}:
        <IntegrationIcon integration={item?.integration} variant='rounded' />
        <strong>{item.label}</strong>
      </Flex>
    )
  }
  return (
    <Flex justifyItems={'start'} alignItems={'center'} gap={2} width={'full'}>
      <IntegrationIcon integration={item?.integration} variant='rounded' />{' '}
      {item.label}
    </Flex>
  )
}
