import { useRouter } from 'next/router'
import CallOut from 'components/Docs/CallOut'
import {
  DocH1,
  DocH2,
  DocH3,
  DocH4,
  DocH5,
  DocH6,
} from 'components/Docs/DocHeadings'
import DocImage from 'components/Docs/DocImage'
import DocLink from 'components/Docs/DocLink'
import { DocGrid, DocPanel } from 'components/Docs/DocLinksGrid'
import ExampleCodeBlock from 'components/Docs/ExampleCodeBlock'
import ExampleFlowBlock from 'components/Docs/ExampleFlowBlock'
import ExampleUIBlock from 'components/Docs/ExampleUIBlock'
import PreTagHighlighted from 'components/Docs/PreTagHighlighted'
import SubHeader from 'components/Docs/SubHeader'
import { UniversalLink } from 'routes/components/UniversalLink'
import { CodeExample } from 'components/CodeExamples'
import useWorkspace from '../../../components/Workspaces/workspace-context'

function SimpleLink({ path, children }) {
  const { route } = useRouter()
  const { workspace } = useWorkspace()

  const isDocInReactRouter = route.startsWith('/w/[workspaceId]')

  // If the path is external, we should use a regular anchor tag
  if (path.startsWith('http://') || path.startsWith('https://')) {
    return <a href={path}>{children}</a>
  }

  const safePath = path.startsWith('/') ? path : `/${path}`

  // If the path is internal for the current workspace
  // render path as is
  if (isDocInReactRouter) {
    return <UniversalLink to={safePath}>{children}</UniversalLink>
  }

  // This is next-router docs
  // We should add `/w/0` to the path if it's not there
  // for correct routing to the workspace
  const workspaceId = workspace?.id ?? 0
  const safeWorkspacePath = `/w/${workspaceId}${safePath}`

  return (
    <UniversalLink isNextLink to={safeWorkspacePath}>
      {children}
    </UniversalLink>
  )
}

export const mdxProviderComponents = {
  DocGrid,
  DocPanel,
  DocLink,
  ExampleCodeBlock,
  ExampleFlowBlock,
  ExampleUIBlock,
  Link: SimpleLink,
  SubHeader,
  CodeExample,
  // Markdown elements
  blockquote: CallOut,
  pre: PreTagHighlighted,
  img: DocImage,
  Image: DocImage,
  h1: DocH1,
  h2: DocH2,
  h3: DocH3,
  h4: DocH4,
  h5: DocH5,
  h6: DocH6,
}
