import { useIntegration, useIntegrationApp } from '@integration-app/react'
import { Integration } from '@integration-app/sdk'
import { IntegrationIcon } from '@integration-app/ui'
import { Combobox, SelectItemType, ComboboxProps } from 'components/Select'
import React, { useState, useRef } from 'react'
import { Flex } from 'styled-system/jsx'
import { Text } from 'ui-kit/text'
import { useDebounceValue } from 'usehooks-ts'

type IntegrationComboboxItemType = SelectItemType & {
  integration?: Integration
}

export function IntegrationComboboxSelect({
  value,
  onChange,
  useId,
  name,
  ...props
}: Omit<ComboboxProps, 'items' | 'value' | 'onChange'> & {
  value?: string
  onChange: (value?: string) => void
  useId?: boolean
  name?: string
}) {
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue(
    '',
    300,
  )
  const integrations = useCachedIntegrations({
    search: debouncedSearchValue,
    useId,
  })

  const integration = useCachedIntegration(value, { useId })

  function handleChange(item: SelectItemType) {
    onChange(item?.value)
  }

  return (
    <Combobox
      items={integrations}
      value={integration}
      onValueChange={handleChange}
      placeholder={'Select an integration'}
      onSearchInputChange={setDebouncedSearchValue}
      TriggerTextComponent={(props) => <ComboboxItem {...props} name={name} />}
      ItemTextComponent={(props) => <ComboboxItem {...props} />}
      {...props}
    />
  )
}

function convertIntegrationToOption(
  integration?: Integration,
  { useId }: { useId?: boolean } = {},
): undefined | IntegrationComboboxItemType {
  if (!integration) {
    return undefined
  }
  return {
    value: useId ? integration.id : integration.key,
    label: integration.name,
    integration: integration,
  }
}

function ComboboxItem({
  item,
  name,
}: {
  item: IntegrationComboboxItemType
  name?: string
}) {
  return (
    <Flex justifyItems={'start'} alignItems={'center'} gap={2} width={'full'}>
      {!!name && <Text as={'span'}>{name}: </Text>}
      <IntegrationIcon integration={item?.integration} variant='rounded' />
      <Text as={'span'}>{item.label}</Text>
    </Flex>
  )
}

function useCachedIntegration(
  integrationId?: string,
  { useId }: { useId?: boolean } = {},
): IntegrationComboboxItemType | undefined {
  const cache = useRef<Record<string, IntegrationComboboxItemType>>({})
  const { integration } = useIntegration(integrationId)

  if (!integrationId || !integration) {
    return undefined
  }

  if (cache.current[integrationId]) {
    return cache.current[integrationId]
  }

  const returnValue = convertIntegrationToOption(integration, {
    useId,
  }) as IntegrationComboboxItemType
  cache.current[integrationId] = returnValue
  return returnValue
}

function useCachedIntegrations({
  search,
  useId,
}: {
  search: string
  useId?: boolean
}): IntegrationComboboxItemType[] {
  const cache = useRef<Record<string, IntegrationComboboxItemType[]>>({})

  const client = useIntegrationApp()
  const [integrations, setIntegrations] = useState<
    IntegrationComboboxItemType[]
  >([])

  if (cache.current[search]) {
    return cache.current[search]
  }

  client.integrations
    .find({
      search,
    })
    .then(({ items }) => items ?? [])
    .then((integrations) =>
      integrations
        .map((i) => convertIntegrationToOption(i, { useId }))
        .filter((x) => !!x),
    )
    .then((items) => {
      setIntegrations(items)
      cache.current[search] = items
    })
    .catch(console.error)

  return integrations
}
