import { ItemTextComponentType } from 'components/Select'
import React, { ComponentProps } from 'react'
import { Text, TextProps } from 'ui-kit/text'

export function OptionItem({
  item,
  asTrigger,
  name,
  ...props
}: TextProps &
  ComponentProps<ItemTextComponentType> & {
    asTrigger?: boolean
    name?: string
  }) {
  if (asTrigger && name) {
    return (
      <Text as={'span'} {...props}>
        {name}: <Text as={'strong'}>{item.label}</Text>
      </Text>
    )
  }
  return (
    <Text as={'span'} {...props}>
      {item.label}
    </Text>
  )
}
