import { ConfigCard } from 'components/Card/ConfigCard'
import { HttpRequestConfig } from '../../../../../../../components/common-configs/HttpRequestConfig'
import { HttpResponseConfig } from '../../../../../../../components/common-configs/HttpResponseConfig'

export function CustomHttpRequest() {
  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header>
          <ConfigCard.Title>Custom HTTP Request</ConfigCard.Title>
        </ConfigCard.Header>
      </ConfigCard.Root>
      <ConfigCard.NestedWrapper>
        <HttpRequestConfig />

        <HttpResponseConfig />
      </ConfigCard.NestedWrapper>
    </>
  )
}
