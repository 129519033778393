import { ImgHTMLAttributes, PropsWithChildren, CSSProperties } from 'react'
import clsx from 'utils/clsx'
import classes from './DocImage.module.css'

export default function DocImage({
  className,
  alt,
  title,
  border,
  maxWidth,
  style,
  ...props
}: ImgHTMLAttributes<HTMLImageElement> & {
  border?: boolean
  maxWidth?: number
}) {
  const caption = title || alt
  const imgStyle: CSSProperties = style ? style : {}
  if (maxWidth) {
    imgStyle.maxWidth = maxWidth
  }

  return (
    <ImageWithCaption caption={caption}>
      <img
        className={clsx(
          classes.image,
          border && classes.image__border,
          caption && classes.image__caption,
          className,
        )}
        alt={alt}
        title={title}
        {...props}
        style={imgStyle}
      />
    </ImageWithCaption>
  )
}

function ImageWithCaption({
  caption,
  children,
}: PropsWithChildren<{ caption?: string }>) {
  if (caption) {
    return (
      <figure className={classes.figure}>
        {caption && (
          <figcaption className={classes.caption}>{caption}</figcaption>
        )}
        {children}
      </figure>
    )
  }

  return <>{children}</>
}
