import { generateExampleFromSchema } from '@integration-app/sdk'
import Editor from '@monaco-editor/react'
import { ConfigCard } from 'components/Card/ConfigCard'
import { JsonViewer } from 'components/JsonViewer'
import { SchemaBuilder } from '@integration-app/ui'
import { Box } from 'styled-system/jsx'
import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'

export const DEFAULT_CODE = `module.exports = async function({
  input,
  externalApiClient,
}) {
  return 'result'
}`

export function RunJavascript() {
  const { config, patchConfig, variablesSchema } = useGenericConfig()

  const variablesExample = generateExampleFromSchema(variablesSchema)

  const code = config.code

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header>
          <ConfigCard.Title>Javascript Code</ConfigCard.Title>
        </ConfigCard.Header>
        <ConfigCard.Content>
          <Box height={96} borderWidth={1} borderRadius={'l2'} paddingBlock={4}>
            <Editor
              width='100%'
              height='100%'
              language='javascript'
              value={code}
              onChange={(newCode: string) => patchConfig({ code: newCode })}
              options={{
                minimap: {
                  enabled: false,
                },
              }}
            />
          </Box>
        </ConfigCard.Content>
      </ConfigCard.Root>
      <ConfigCard.NestedWrapper>
        <ConfigCard.Root>
          <ConfigCard.Header>
            <ConfigCard.Title>Variables Example</ConfigCard.Title>
          </ConfigCard.Header>
          <ConfigCard.Content paddingInlineStart={7}>
            <JsonViewer json={variablesExample} />
          </ConfigCard.Content>
        </ConfigCard.Root>
        <ConfigCard.Root>
          <ConfigCard.Header>
            <ConfigCard.Title>Output Schema</ConfigCard.Title>
          </ConfigCard.Header>
          <ConfigCard.Content>
            <SchemaBuilder
              schema={config.outputSchema ?? {}}
              isObject={false}
              onChange={(outputSchema) => patchConfig({ outputSchema })}
            />
          </ConfigCard.Content>
        </ConfigCard.Root>
      </ConfigCard.NestedWrapper>
    </>
  )
}
