import { makeDataField } from '@integration-app/ui/DataBuilder'
import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import { ConfigCard } from 'components/Card/ConfigCard'
import { getBrokenVariablesConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/getBrokenVariablesConfigurationErrors'
import { NodeConfigurationErrorsGetter } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'
import { DataLinkTableConfigWithCreatePopup } from './DataLinkTableConfigWithCreatePopup'
import { useGenericConfig } from './contexts/generic-config-context'
import { Text } from 'ui-kit/text'

export function FindOrCreateDataRecordDataLinkConfig() {
  const { dataLinkConfig, patchDataLinkConfig, variablesSchema } =
    useGenericConfig()

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Data Link</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={2}>
        <Text m={0}>
          Select Data Link Table and App Record Id data to find the link
        </Text>

        <DataLinkTableConfigWithCreatePopup
          dataLinkTableKey={dataLinkConfig?.dataLinkTableKey}
          onChange={(dataLinkTableKey) =>
            patchDataLinkConfig({ dataLinkTableKey })
          }
        />

        <AdminDataBuilderForm
          onChange={(appRecordId) => patchDataLinkConfig({ appRecordId })}
          field={makeDataField({
            schema: {
              type: 'string',
              title: 'App Record ID',
            },
            value: dataLinkConfig.appRecordId,
            variablesSchema,
          })}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}

export const getFindOrCreateDataRecordDataLinkConfigErrors: NodeConfigurationErrorsGetter =
  ({ runTimeVariablesSchema, config }) => {
    return getBrokenVariablesConfigurationErrors(config?.dataLink, [
      runTimeVariablesSchema,
    ])
  }
