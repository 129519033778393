import { DataSource } from '@integration-app/sdk'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { routeTo } from '../../routes-constants'
import { ConfigCard } from 'components/Card/ConfigCard'
import { GoToElementLink } from 'components/GoToElemenLink'

export default function RelatedDataSourceSection({
  dataSource,
}: {
  dataSource?: DataSource
}) {
  if (!dataSource) {
    return null
  }
  return (
    <ConfigCard.Root>
      <ConfigCard.Header
        leftSlot={<SvgIcon type={SvgIconType.DataSourceEntity} />}
      >
        <ConfigCard.Title>
          Data Source:
          <GoToElementLink to={routeTo.dataSource(dataSource)}>
            {dataSource.name}
          </GoToElementLink>
        </ConfigCard.Title>
      </ConfigCard.Header>
    </ConfigCard.Root>
  )
}
