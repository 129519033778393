import { Portal } from '@ark-ui/react'
import { ErrorData } from '@integration-app/sdk'
import { Popover } from 'components/Popover'
import { ErrorItem } from 'components/Error/ErrorItem'
import { useState } from 'react'
import { TbChevronDown, TbChevronLeft } from 'react-icons/tb'
import { Badge } from 'ui-kit/badge'
import { Icon } from 'ui-kit/icon'
import { Text } from 'ui-kit/text'
import { getArticle } from 'routes/Docs/helpers/articleList'

export function ErrorsBadge({
  title,
  errors,
  usePortal,
}: {
  title: string | ((n: number) => string)
  errors?: ErrorData[]
  usePortal?: boolean
}) {
  if (!errors || errors.length === 0) return null

  const [selectedError, setSelectedError] = useState<ErrorData | null>(null)
  const computedTitle = typeof title === 'string' ? title : title(errors.length)

  function resetSelectedError() {
    setSelectedError(null)
  }

  return (
    <Popover.Root lazyMount unmountOnExit onExitComplete={resetSelectedError}>
      <Popover.Trigger asChild>
        <Badge colorPalette='error' variant={'subtle'} cursor={'pointer'}>
          {computedTitle}
          <Icon size={'sm'}>
            <TbChevronDown />
          </Icon>
        </Badge>
      </Popover.Trigger>
      <Portal disabled={!usePortal}>
        <Popover.Window width={'lg'}>
          <Popover.Content>
            {!selectedError && (
              <ErrorsList errors={errors} onErrorClick={setSelectedError} />
            )}

            {selectedError && (
              <Text
                m={0}
                color={'fg.muted'}
                size={'xs'}
                onClick={resetSelectedError}
                display={'inline-flex'}
                alignItems={'center'}
                marginBlockEnd={4}
                gap={1}
                _hover={{
                  cursor: 'pointer',
                  color: 'fg.default',
                }}
              >
                <Icon size={'sm'}>
                  <TbChevronLeft />
                </Icon>
                Back to the list of errors
              </Text>
            )}
            {selectedError && <ErrorItemDetailed error={selectedError} />}
          </Popover.Content>
        </Popover.Window>
      </Portal>
    </Popover.Root>
  )
}

function ErrorsList({
  errors,
  onErrorClick,
}: {
  errors: ErrorData[]
  onErrorClick: (error: ErrorData) => void
}) {
  return (
    <ErrorItem.List>
      {errors.map((error, i) => (
        <ErrorItem.Root key={i}>
          <ErrorItem.Header>
            <ErrorBadge error={error} />
            <ErrorItem.Title>{error.message}</ErrorItem.Title>
          </ErrorItem.Header>
          <ErrorJSONPreview error={error} onClick={() => onErrorClick(error)} />
          <ErrorDocsLink error={error} />
        </ErrorItem.Root>
      ))}
    </ErrorItem.List>
  )
}

function ErrorItemDetailed({ error }: { error: ErrorData }) {
  if (!error) {
    return null
  }

  return (
    <ErrorItem.Root>
      <ErrorItem.Header>
        <ErrorBadge error={error} />
        <ErrorItem.Title>{error.message}</ErrorItem.Title>
      </ErrorItem.Header>

      <ErrorJSON error={error} />

      <ErrorDocsLink error={error} />
    </ErrorItem.Root>
  )
}

function ErrorBadge({ error }: { error: ErrorData }) {
  if (!(error.type || error.key)) return null

  return (
    <ErrorItem.Badge>
      {error.type} {!!error.key && <span>{error.key}</span>}
    </ErrorItem.Badge>
  )
}

function ErrorJSONPreview({
  error,
  onClick,
}: {
  error: ErrorData
  onClick: (error: ErrorData) => void
}) {
  const additionalData: Record<string, unknown> = getErrorAdditionalData(error)
  if (!Object.keys(additionalData).length) return null

  return (
    <ErrorItem.JSONPreview
      json={additionalData}
      onClick={() => onClick(error)}
    />
  )
}

function ErrorJSON({ error }: { error: ErrorData }) {
  const additionalData: Record<string, unknown> = getErrorAdditionalData(error)
  if (!Object.keys(additionalData).length) return null

  return <ErrorItem.JSONViewer json={additionalData} />
}

function ErrorDocsLink({ error }: { error: ErrorData }) {
  if (!error?.doc) return null

  const docLink = 'https://console.integration.app/docs/errors/' + error.doc
  const docTitle = `How to resolve this issue: ${
    getArticle('monitoring/activity-log/errors/' + error.doc)?.title
  }`

  return <ErrorItem.DocLink to={docLink}>{docTitle}</ErrorItem.DocLink>
}

function getErrorAdditionalData(error: ErrorData) {
  const { data, logs, causedByError } = error

  const additionalData: Record<string, unknown> = {}
  if (data) {
    additionalData.data = data
  }
  if (logs) {
    additionalData.logs = logs
  }
  if (causedByError) {
    additionalData.causedByError = causedByError
  }

  return additionalData
}
