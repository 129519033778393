import {
  DataSchema,
  UNIFIED_DATA_MODELS,
  schemaWithTitle,
} from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui/DataBuilder'
import { ConfigCard } from 'components/Card/ConfigCard'
import { toHeaderCase } from 'js-convert-case'
import AdminDataBuilderForm from '../../../../../../../components/AdminDataBuilderForm'
import { useFieldMappingContext } from '../contexts/field-mapping-context'
import { GenerateFieldMappingButton } from './GenerateFieldMappingButton'
import { FieldDescription } from '@integration-app/ui'
import { Alert } from 'components/Alert'
import { TbInfoCircle } from 'react-icons/tb'

export function ExportFieldMappingConfig() {
  const {
    fieldMapping,
    isUniversal,
    appSchema,
    onChange,
    dataSource,
    dataCollectionSpec,
  } = useFieldMappingContext()

  const fromCollectionName = dataSource
    ? `Fields in data collection "${dataSource.name}"`
    : 'Fields in my application'
  let toCollectionName = 'Fields in an external application'

  let toSchema: DataSchema | undefined

  if (isUniversal) {
    const udm = dataSource?.udm
      ? UNIFIED_DATA_MODELS[dataSource?.udm]
      : undefined
    if (udm) {
      toSchema = schemaWithTitle(
        udm.fieldsSchema,
        `Universal ${toHeaderCase(udm.singularName)} Fields`,
      )
      toCollectionName = `Universal (standard) ${udm.singularName} Fields`
    } else {
      return (
        <ConfigCard.Root>
          <ConfigCard.Header>
            <ConfigCard.Title>Mapping</ConfigCard.Title>
            <FieldDescription
              text={'Map internal fields to fields in an external app'}
            />
          </ConfigCard.Header>

          <ConfigCard.Content>
            <Alert.Root icon={<TbInfoCircle />}>
              <Alert.Description>
                No Universal Data Model selected. Please select a Data Source
                with UDM or keep these fields empty and set them on the
                application level.
              </Alert.Description>
            </Alert.Root>
          </ConfigCard.Content>
        </ConfigCard.Root>
      )
    }
  } else {
    if (dataCollectionSpec) {
      toSchema = schemaWithTitle(
        dataCollectionSpec.fieldsSchema,
        `${dataCollectionSpec.name} Fields`,
      )
      toCollectionName = `data collection "${
        dataCollectionSpec.name ?? dataCollectionSpec.key
      }"`
    } else {
      return (
        <ConfigCard.Root>
          <ConfigCard.Header>
            <ConfigCard.Title>Mapping</ConfigCard.Title>
            <FieldDescription
              text={'Map internal fields to fields in an external app'}
            />
          </ConfigCard.Header>

          <ConfigCard.Content>
            <Alert.Root icon={<TbInfoCircle />}>
              <Alert.Description>
                No data collection selected. Please select a Data Collection in
                your Data Source to set up mapping.
              </Alert.Description>
            </Alert.Root>
          </ConfigCard.Content>
        </ConfigCard.Root>
      )
    }
  }

  const fromSchema = schemaWithTitle(
    appSchema ?? { type: 'object' },
    'Internal Fields',
  )

  const field = makeDataField({
    schema: toSchema,
    variablesSchema: fromSchema,
    value: isUniversal
      ? fieldMapping.defaultExportValue
      : fieldMapping.exportValue,
  })

  async function handleChange(value: any) {
    return await onChange(
      isUniversal ? { defaultExportValue: value } : { exportValue: value },
    )
  }

  return (
    <ConfigCard.Root>
      <ConfigCard.Header
        rightSlot={
          toSchema &&
          appSchema && (
            <GenerateFieldMappingButton
              fromSchema={appSchema}
              toSchema={toSchema}
              extraPrompt={`This mapping transforms fields from ${fromCollectionName} to ${toCollectionName}`}
              onGenerate={handleChange}
            />
          )
        }
      >
        <ConfigCard.Title>Mapping</ConfigCard.Title>
        <FieldDescription
          text={'Map internal fields to fields in an external app'}
        />
      </ConfigCard.Header>

      <ConfigCard.Content>
        <AdminDataBuilderForm
          field={field}
          hideReadOnlyFields
          onChange={handleChange}
        />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
