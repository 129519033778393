import { ConfigCard } from 'components/Card/ConfigCard'
import { IntegrationSpecificOperationConfig } from '../../../../../../../components/common-configs/IntegrationSpecificOperationConfig'

export function IntegrationSpecificOperation() {
  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>
          Setup Integration Specific Operation
        </ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <IntegrationSpecificOperationConfig />
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
